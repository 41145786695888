import { render, staticRenderFns } from "./Now.vue?vue&type=template&id=58ec2281&scoped=true"
import script from "./Now.vue?vue&type=script&lang=js"
export * from "./Now.vue?vue&type=script&lang=js"
import style0 from "./Now.vue?vue&type=style&index=0&id=58ec2281&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ec2281",
  null
  
)

export default component.exports