<template>
  <scroller
    :on-refresh="refresh"
  >
    <h1 class="text-primary mt-2">
      {{ $t('pages.harvest.now.name') }}
    </h1>
    <p class="text-black-25 mt-0 mb-0 pb-50">
      {{
        lastPullFormat !== null ? $t('pages.harvest.now.updated-at', { date: lastPullFormat }) : $t('therms.loading')
      }}
    </p>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="error">
        <div
          class="mt-2 mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.harvest.now.loading-error') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="refresh(() => {}, true)"
            >
              {{ $t('therms.retry') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <template
          v-if="!isFetch || loader || has"
        >
          <template v-if="!isFetch">
            <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem;"
              />
            </div>
          </template>
          <template v-else>
            <div class="mb-2 mt-2 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <touch-icon-button @action="displayOptions = !displayOptions">
                  <i
                    v-if="!displayOptions"
                    :disabled="loader|| !isFetch"
                    class="fa-solid fa-gear"
                  />
                  <i
                    v-else
                    class="fa-regular fa-gear"
                  />
                </touch-icon-button>
                <div
                  v-if="!displayOptions"
                  :disabled="loader|| !isFetch"
                  class="ml-1"
                >
                  {{ $t('therms.display-options') }}
                </div>
                <div
                  v-else
                  class="ml-1"
                >
                  {{ $t('therms.hide-options') }}
                </div>
              </div>

              <transition name="fade">
                <touch-icon-button
                  v-if="displayOptions"
                  :disabled="loader|| !isFetch"
                  @action="displayFilters = !displayFilters"
                >
                  <i
                    v-if="!displayFilters"
                    style="font-size: 0.9em"
                    class="fa-solid fa-filter"
                  />
                  <i
                    v-else
                    style="font-size: 0.9em"
                    class="fa-solid fa-filter-slash"
                  />
                </touch-icon-button>
              </transition>
            </div>

            <b-card
              class="mb-1"
            >
              <b-row>
                <b-col
                  class="d-flex justify-content-between align-items-center"
                >
                  <b-card-text class="mr-75 mb-0">
                    {{ $t('therms.hide-inactive-harvester') }}
                  </b-card-text>
                  <b-form-checkbox
                    v-model="onlyStaffMemberActive"
                    :disabled="displayFilters && activeStaffMemberSearch !== null"
                    class="custom-control-secondary mr-0"
                    style="position: relative; right: -6px"
                    name="check-button"
                    switch
                    inline
                  />
                </b-col>
              </b-row>
            </b-card>

            <transition name="scale-in">
              <div v-show="displayOptions">

                <transition name="scale-in">
                  <div v-if="displayFilters">
                    <b-card class="mb-1">
                      <b-row>
                        <b-col>
                          <b-card-text class="mb-50">
                            {{ $t('therms.filter-list-by') }} :
                          </b-card-text>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="d-flex">
                          <b-form-input
                            v-model="searchStaffMember"
                            class="mr-75"
                            style="border-radius: 0.6rem"
                            :placeholder="$t('therms.number-lastname-firstname')"
                          />
                          <touch-icon-button
                            v-if="activeStaffMemberSearch === null"
                            :disabled="searchStaffMember === null || searchStaffMember.trim().length === 0"
                            style="min-width: 39px"
                            type="submit"
                            @action="searchStaffMemberHandler"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                              class="cursor-pointer"
                            />
                          </touch-icon-button>
                          <touch-icon-button
                            v-else
                            style="min-width: 39px"
                            color="danger"
                            @action="clearStaffMemberSearch"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="18"
                              class="cursor-pointer"
                            />
                          </touch-icon-button>
                        </b-col>
                      </b-row>
                    </b-card>
                    <b-card class="mb-1">
                      <b-row>
                        <b-col>
                          <b-card-text class="mb-50">
                            {{ $t('therms.filter-list-by-time') }} :
                          </b-card-text>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="d-flex">
                          <b-form-input
                            v-model="from_time"
                            :disabled="loader|| !isFetch"
                            class="mr-75 d-flex"
                            type="time"
                            style="border-radius: 0.6rem"
                            :placeholder="$t('therms.start-time')"
                          />
                          <b-form-input
                            v-model="to_time"
                            :disabled="loader|| !isFetch"
                            class="mr-75 d-flex"
                            type="time"
                            style="border-radius: 0.6rem"
                            :placeholder="$t('therms.end-time')"
                          />
                          <touch-icon-button
                            v-if="activeTimeSearch === null || (from_time !== activeTimeSearch.from_time || to_time !== activeTimeSearch.to_time)"
                            :disabled="loader|| !isFetch || from_time === null || to_time === null"
                            style="min-width: 39px"
                            type="submit"
                            @action="searchTimeHandler"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                              class="cursor-pointer"
                            />
                          </touch-icon-button>
                          <touch-icon-button
                            v-else
                            :disabled="loader|| !isFetch"
                            style="min-width: 39px"
                            color="danger"
                            type="button"
                            @action="clearTimeSearch"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="18"
                              class="cursor-pointer"
                            />
                          </touch-icon-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          class="mt-1 d-flex"
                          style="gap: 8px"
                        >
                          <touch-button
                            :outline="!morningTimeIsSelected"
                            :disabled="loader|| !isFetch"
                            size="xs"
                            type="submit"
                            @action="selectMorningTime"
                          >
                            {{ $t('therms.morning') }}
                          </touch-button>
                          <touch-button
                            :outline="!afternoonTimeIsSelected"
                            :disabled="loader|| !isFetch"
                            size="xs"
                            type="submit"
                            @action="selectAfternoonTime"
                          >
                            {{ $t('therms.afternoon') }}
                          </touch-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </transition>

                <b-card
                  class="mb-2"
                >
                  <b-row>
                    <b-col>
                      <b-card-text class="mb-50">
                        {{ $t('therms.sort-list-by') }} :
                      </b-card-text>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <touch-button-group class="w-100 d-flex">
                        <touch-button
                          outline
                          class="flex-fill"
                          :class="{'active' : sort === sortPerWeight}"
                          ingroup
                          size="sm"
                          style="width: 33%"
                          @action="sort = sortPerWeight"
                        >
                          <span>{{
                            capitalizeFirstLetter($t('units.kilogram')
                              .toString())
                          }}</span>
                        </touch-button>
                        <touch-button
                          outline
                          class="flex-fill"
                          :class="{'active' : sort === sortPerQuantity}"
                          ingroup
                          size="sm"
                          style="width: 33%"
                          @action="sort = sortPerQuantity"
                        >
                          <span>{{
                            capitalizeFirstLetter($t('units.quantity')
                              .toString())
                          }}</span>
                        </touch-button>
                        <touch-button
                          outline
                          class="flex-fill"
                          :class="{'active' : sort === sortPerCode}"
                          ingroup
                          size="sm"
                          style="width: 33%"
                          @action="sort = sortPerCode"
                        >
                          <span>{{
                            capitalizeFirstLetter($t('therms.number')
                              .toString())
                          }}</span>
                        </touch-button>
                      </touch-button-group>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </transition>
            <div class="mt-3" />

            <template v-if="loader">
              <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
                <b-spinner
                  variant="primary"
                  style="width: 3rem; height: 3rem;"
                />
              </div>
            </template>
            <template v-else>
              <PickingCardResume
                v-for="crop in harvestCropStaffMember"
                :id="crop.id"
                :key="crop.id"
                class="animate__animated animate__fadeInUp"
                :emoji="crop.emoji"
                :name="crop.name"
                :total-weight="crop.total_weight"
                :quantity="crop.quantity"
                :items="crop.harvest"
                :variant="'primary'"
                :filter-active="onlyStaffMemberActive && activeStaffMemberSearch === null"
                :sort-by="sort"
                :animated="true"
              />
            </template>
          </template>
        </template>
        <template v-else>
          <div class="text-center px-50">
            <span style="font-size: 6.5em">
              🚧
            </span>
            <h7 class="mt-1">
              {{ $t('therms.no-harvest') }}
            </h7>
            <p class="text-black-50 mt-0 mb-2 pb-50">
              {{ $t('pages.harvest.now.no-data') }}
            </p>
          </div>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import PickingCardResume from '@/views/components/PickingCardResume.vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import {
  BCard, BCardText, BCol, BFormCheckbox, BFormInput, BRow, BSpinner,
} from 'bootstrap-vue'
import { sortPerCode, sortPerQuantity, sortPerWeight } from '@/declations/PickingResumeSort'
import TouchButtonGroup from '@core/components/touch-button/TouchButtonGroup.vue'
import TouchIconButton from '@core/components/touch-button/TouchIconButton.vue'
import { mapGetters } from 'vuex'

const morningTime = ['00:00', '11:59']
const afternoonTime = ['12:00', '23:59']

export default {
  components: {
    PickingCardResume,
    TouchButton,
    TouchButtonGroup,
    TouchIconButton,
    BSpinner,
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      // ui
      isFetching: false,
      error: false,
      loader: false,
      displayOptions: false,
      displayFilters: false,
      onlyStaffMemberActive: true,
      sort: sortPerWeight,

      // data
      harvestCrop: null,
      searchStaffMember: null,
      activeStaffMemberSearch: null,
      from_time: null,
      to_time: null,
      activeTimeSearch: null,
    }
  },
  computed: {
    ...mapGetters({
      has: 'harvestNow/has',
      isFetch: 'harvestNow/isFetch',
      lastPull: 'harvestNow/getLastPull',
    }),
    /**
     * @returns {string|null}
     */
    lastPullFormat() {
      if (!this.lastPull) return null
      return (`${this.lastPull?.format('LT')}`).toLowerCase()
    },
    /**
     * @returns {string}
     */
    sortPerQuantity() {
      return sortPerQuantity
    },
    /**
     * @returns {string}
     */
    sortPerWeight() {
      return sortPerWeight
    },
    /**
     * @returns {string}
     */
    sortPerCode() {
      return sortPerCode
    },
    /**
     * @returns {*}
     */
    harvestCropStaffMember() {
      if (this.harvestCrop === null) return []

      return JSON.parse(JSON.stringify(this.harvestCrop))
        .map(crop => {
          // eslint-disable-next-line no-param-reassign
          crop.harvest = crop.harvest
            .map(staffMember => {
              // eslint-disable-next-line no-param-reassign
              staffMember.name = `${staffMember.firstname.trim()} ${staffMember.lastname.trim()}`.trim()
              return staffMember
            })

          if (this.activeStaffMemberSearch !== null) {
            // eslint-disable-next-line no-param-reassign
            crop.harvest = crop.harvest.filter(st => st.name.toLowerCase()
              .indexOf(this.activeStaffMemberSearch.toLowerCase()) !== -1
                  || (`${st.code}`).toLowerCase()
                    .indexOf(this.activeStaffMemberSearch.toLowerCase()) !== -1)
          }

          return crop
        })
    },
    morningTimeIsSelected() {
      return this.from_time === morningTime[0] && this.to_time === morningTime[1]
    },
    afternoonTimeIsSelected() {
      return this.from_time === afternoonTime[0] && this.to_time === afternoonTime[1]
    },
  },
  watch: {
    displayFilters(val) {
      if (!val) {
        this.clearStaffMemberSearch()
        this.clearTimeSearch()
      }
    },
    displayOptions(val) {
      if (!val) {
        this.clearStaffMemberSearch()
        this.clearTimeSearch()
      }
    },
  },
  async mounted() {
    this.error = false

    this.emitter.on('appStateChange', async isActive => {
      if (this.needFetch(isActive)) await this.fetch(true)
    })

    try {
      if (this.needFetch(true)) {
        await this.fetch(true)
      } else {
        this.loadData()
      }
    } catch (err) {
      this.error = true
      throw err
    }
  },
  beforeDestroy() {
    this.emitter.off('appStateChange')
  },
  methods: {
    /**
     * @param {string} string
     * @return {string}
     */
    capitalizeFirstLetter(string) {
      return window.capitalizeFirstLetter(string)
    },
    /**
     * @param {Boolean} isActive
     * @returns {boolean}
     */
    needFetch(isActive = false) {
      if (this.$store.getters['harvestNow/getLastPull'] === null) return true

      return (isActive && (this.$store.getters['harvestNow/getLastPull'].format('YYYY-MM-DD') !== this.$moment()
        .format('YYYY-MM-DD')
          || this.$moment()
            .diff(this.$store.getters['harvestNow/getLastPull'], 'minutes') >= 5))
    },
    /**
     * @param {Boolean} clear
     * @param {Boolean} force
     * @param {Boolean} loader
     */
    async fetch(clear = false, force = false, loader = true) {
      if (this.isFetching) return
      this.isFetching = true
      this.loader = loader

      const filters = {
        ...(this.activeTimeSearch === null ? {} : {
          from_time: `${this.activeTimeSearch.from_time}:00`,
          to_time: `${this.activeTimeSearch.to_time}:59`,
        }
        ),
      }

      if (clear) this.$store.commit('harvestNow/clear')
      if (Object.keys(filters).length === 0) {
        if (force || !this.has) {
          await this.$store.dispatch('harvestNow/fetch', {})
        }
        this.harvestCrop = this.$store.getters['harvestNow/getCropHarvest']
      } else {
        const harvestCrop = await this.$store.dispatch('harvestNow/fetch', {
          filters,
          byPassStorageCaching: true,
        })

        await sleep(100)
        this.harvestCrop = harvestCrop
      }

      this.loader = false
      this.isFetching = false
    },
    loadData() {
      if (!this.has) return this.fetch()
      this.harvestCrop = this.$store.getters['harvestNow/getCropHarvest']
    },
    /**
     * @param {Function} finishToRefresh
     * @param {Boolean} clear
     * @returns {Promise<void>}
     */
    // eslint-disable-next-line no-unused-vars
    async refresh(finishToRefresh, clear = false) {
      this.error = false
      if (this.isFetching) return finishToRefresh()

      try {
        await this.fetch(clear, true, false)
        finishToRefresh()
      } catch (err) {
        this.error = true
        finishToRefresh()

        throw err
      }
      finishToRefresh()
    },
    searchStaffMemberHandler() {
      if (this.searchStaffMember !== null && this.searchStaffMember.trim().length === 0) {
        this.clearStaffMemberSearch()
        return
      }

      this.activeStaffMemberSearch = this.searchStaffMember
    },
    clearStaffMemberSearch() {
      this.activeStaffMemberSearch = null
      this.searchStaffMember = null
    },
    searchTimeHandler() {
      if (this.from_time === null || this.to_time === null) {
        return this.clearTimeSearch()
      }

      this.activeTimeSearch = {
        from_time: this.from_time,
        to_time: this.to_time,
      }

      this.fetch()
    },
    clearTimeSearch() {
      this.activeTimeSearch = null
      this.from_time = null
      this.to_time = null

      this.fetch()
    },
    selectMorningTime() {
      this.from_time = morningTime[0]
      this.to_time = morningTime[1]

      this.searchTimeHandler()
    },
    selectAfternoonTime() {
      this.from_time = afternoonTime[0]
      this.to_time = afternoonTime[1]
      this.searchTimeHandler()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

.card.card-chart {
  .card-title {
    margin-bottom: 10px;
  }
}
</style>
